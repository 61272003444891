import Vue from "vue";
import VueRouter from "vue-router";

import axios from "axios";
import Swal from "sweetalert2";

Vue.use(VueRouter);

// import Dashboard from "../pages/Dashboard.vue";
// import Login from "../pages/Login.vue";
// import Home from "../pages/landing-page/Home.vue";

const routes = [
  {
    path: "/",
    component: () => import("../pages/landing-page/Home.vue"),
  },
  {
    path: "/home",
    component: () => import("../pages/landing-page/Home.vue"),
  },
  {
    path: "*",
    component: () => import("../pages/NotFound.vue"),
  },
  {
    path: "/forbidden",
    component: () => import("../pages/Forbidden.vue"),
  },
  {
    path: "/kuisioner",
    component: () => import("../pages/landing-page/Kuisioner.vue"),
  },
  {
    path: "/dashboard/",
    component: () => import("../pages/Dashboard.vue"),
  },
  {
    path: "/login/",
    // component: Login,
    component: () => import("../pages/Login.vue"),
  },
  {
    path: "/data-responden/",
    component: () => import("../pages/responden/List.vue"),
    meta: {
      requiredLevel: ["admin"],
    },
  },
  {
    path: "/data-responden/import",
    component: () => import("../pages/responden/Import.vue"),
    meta: {
      requiredLevel: ["admin"],
    },
  },
  {
    path: "/daerah-irigasi/",
    component: () => import("../pages/team/List.vue"),
    meta: {
      requiredLevel: ["admin"],
    },
  },
  {
    path: "/daerah-irigasi/detail/:id",
    component: () => import("../pages/team/Detail.vue"),
    meta: {
      requiredLevel: ["admin"],
    },
  },
  {
    path: "/data-verifikasi/",
    component: () => import("../pages/data-verifikasi/List.vue"),
    meta: {
      requiredLevel: ["admin", "mahasiswa", "dpl"],
    },
  },
  {
    path: "/data-verifikasi/formulir",
    component: () => import("../pages/data-verifikasi/Form.vue"),
    meta: {
      requiredLevel: ["admin", "mahasiswa"],
    },
  },
  {
    path: "/data-verifikasi/detail/:id",
    component: () => import("../pages/data-verifikasi/Detail.vue"),
  },

  {
    path: "/pengguna/",
    component: () => import("../pages/pengguna/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/pengguna/create",
    component: () => import("../pages/pengguna/Form.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/pengguna/update/:id",
    component: () => import("../pages/pengguna/Edit.vue"),
  },
  {
    path: "/pengguna/password/:id",
    component: () => import("../pages/pengguna/Password.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("userData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (
    !isLoggedIn &&
    to.path !== "/login" &&
    to.path !== "/home" &&
    to.path !== "/" &&
    to.path !== "/kuisioner"
  ) {
    next("/");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }

  const userData = localStorage.getItem("userData");
  if (userData) {
    const user = JSON.parse(userData);

    axios
      .get("/v1/auth/status/", {
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Session Habis, Silahkan Login Kembali",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            localStorage.removeItem("userData");
            if (to.path !== "/") {
              next("/");
            }
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default router;
