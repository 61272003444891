<template>
  <div :class="{ 'sb-nav-fixed': !isLoginPage }">
    <Navbar v-if="!isLoginPage"></Navbar>
    <div :id="layoutId">
      <Sidebar v-if="!isLoginPage"></Sidebar>
      <div :id="contentId">
        <router-view></router-view>
        <Footer v-if="!isLoginPage"></Footer>
      </div>
    </div>
    <a
      target="_blank"
      href="https://wa.me/+628122967201"
      id="printback"
      class="btn btn-float-bottom btn-green"
    >
      <font-awesome-icon :icon="['fab', 'whatsapp']" size="2x" />
    </a>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Sidebar from "./components/Sidebar.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Sidebar,
    Footer,
  },
  mounted() {
    // console.log(this.$route);
  },
  computed: {
    isLoginPage() {
      if (
        this.$route.path === "/" ||
        this.$route.path === "/home" ||
        this.$route.path === "/login" ||
        this.$route.path === "/kuisioner"
      ) {
        return true;
      } else {
        return false;
      }
    },
    layoutId() {
      return this.isLoginPage ? "layoutAuthentication" : "layoutSidenav";
    },
    contentId() {
      return this.isLoginPage
        ? "layoutAuthentication_content"
        : "layoutSidenav_content";
    },
  },
};
</script>
<style scoped>
body {
  min-height: 100vh;
}
.btn-green {
  background-color: #14692e;
}
.btn-green:hover {
  background-color: #00a884;
}
.btn-float-bottom {
  width: 50px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: fixed;
  right: 30px;
  bottom: 30px;
}
</style>
